import { getCmsPathPartsAsArray } from '../pathCheck'

export const updateEnrichment = (path, uniformContext, enrStr = 10) => {
  if (!path || !uniformContext || !getCmsPathPartsAsArray(path)) {
    console.error('UniformEnrichmentError', 'Invalid data passed to enrichment')
    return
  }

  const sanitisedKey = (key) => {
    return key.replace(' ', '-').toLowerCase()
  }

  try {
    const [cat, key] = getCmsPathPartsAsArray(path)
    uniformContext.update({
      enrichments: [
        {
          str: enrStr,
          cat: cat === 'brands' ? 'brands-page' : cat,
          key: sanitisedKey(key),
        },
      ],
    })
  } catch (e) {
    console.error('Error adding enrichment', e)
  }
}
