import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { ErrorBoundary, PageNotFound } from '@sainsburys-tech/bolt'
import Status from '../Status/Status'
import { getBoltClass } from '../../helpers/brandHelper'

const boltClass = getBoltClass()

const PageNotFoundWrapper = ({ history }) => {
  useEffect(() => {
    document.title = '404 Not Found'
  }, [])

  const route = (path) => history && history.push(path)
  const links = [
    {
      text: 'Take me home',
      title: 'Take me home: Argos homepage',
      handler: () => route('/'),
    },
    {
      text: 'Contact Us',
      href: '/help/contact-us',
      title: 'Contact us: Argos help pages',
    },
  ]

  return (
    <Status code={404}>
      <div className={`bolt-${boltClass} container`} style={{ marginTop: 15 }}>
        <ErrorBoundary>
          <PageNotFound links={links} />
        </ErrorBoundary>
      </div>
    </Status>
  )
}

PageNotFoundWrapper.propTypes = {
  history: PropTypes.shape({
    action: PropTypes.oneOf(['PUSH', 'REPLACE', 'POP']).isRequired,
    block: PropTypes.func.isRequired,
    canGo: PropTypes.func,
    createHref: PropTypes.func.isRequired,
    entries: PropTypes.arrayOf(PropTypes.object),
    go: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
    goForward: PropTypes.func.isRequired,
    index: PropTypes.number,
    length: PropTypes.number,
    listen: PropTypes.func.isRequired,
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }).isRequired,
    push: PropTypes.func.isRequired,
    replace: PropTypes.func.isRequired,
  }),
}

PageNotFoundWrapper.defaultProps = {
  history: {},
}

export default PageNotFoundWrapper
