import styled, { createGlobalStyle, css } from 'styled-components'

const CmsContainer = styled.div`
  margin-top: 15px;
  align-items: center;

  > * {
    width: 100%;
  }

  ${(props) =>
    props.theme.channel === 'habitat' &&
    css`
      margin-bottom: 15px;

      @media (min-width: ${props.theme.size.breakpoint['min-width'].sm}) {
        margin-bottom: 25px;
      }
    `}
`

const CmsGlobalStyle = createGlobalStyle`
.container--events-clearance {
    [data-test='m0012'] {
      background-color: ${(props) => props.theme.color.palette['brand-4']};

      [data-test='m0012-image'] {
        background-image: none !important;
        display: none;
      }
    }

    [data-test='m035-container'] {
      a {
        background-color: ${(props) => props.theme.color.palette['brand-4']};
        color: ${(props) => props.theme.color.monochrome.black};
      }
    }

    .m002 {
      & > div:first-child,
      &__link-background {
        background-color: ${(props) => props.theme.color.palette['brand-4']};
        color: ${(props) => props.theme.color.monochrome.black};
      }

      &__header,
      &__link {
        color: ${(props) => props.theme.color.monochrome.black};
      }

      &__cta button {
        color: ${(props) => props.theme.color.monochrome.black};
        border-color: ${(props) => props.theme.color.monochrome.black};
        background-color: ${(props) => props.theme.color.palette['brand-4']};
      }

      &__svg--chevron {
        fill: ${(props) => props.theme.color.monochrome.black};
      }

      &__svg--expand {
        color: black;
      }
    }
  }

.full-width {
  @media (min-width: ${(props) => props.theme.size.breakpoint['min-width'].md}) {
    width: 98vw;
    max-width: ${(props) => props.theme.size.breakpoint['min-width']['2xl']};
  }
}

.m022 {
  flex: 1 1 auto !important;

  &__quote {
    border-color: #ccc;
  }
}

.m0043 {
  height: auto !important;
  min-height: auto !important;
}

.gl0043 {
  .m010 {
    display: grid;
  }

  .m0044 {
    @media (min-width: ${(props) => props.theme.size.breakpoint['min-width'].sm}) {
      margin: 0;
    }
    .ac-product-card__cta-row {
      @media (max-width: ${(props) => props.theme.size.breakpoint['min-width'].md}) {
        right: 0;
        left: unset;
        .ac-product-cta {
          margin-right: 0;
        }
      }
      @media (min-width: ${(props) => props.theme.size.breakpoint['min-width'].md}) {
        margin-bottom: 15px;
      }
    }
    border-bottom: 0;
  }
}
`
export { CmsContainer, CmsGlobalStyle }
