import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import throttle from 'lodash.throttle'
import { getTemplateLevelAttrs } from '../../helpers/ContentHelper'

const AnchorNavProvider = ({
  navObject,
  navActivePos,
  activeSection,
  topDeadZone,
  bottomDeadZone,
  viewChecker,
  cmsData,
  children,
  scrollPosition,
}) => {
  const [pos, setScrollPosition] = useState(0)
  function checkInView(pageYOffset) {
    throttle(() => {
      if (!!navObject && navObject.length) {
        const isBetween = (windowScroll, top, bottom) => (windowScroll - top) * (windowScroll - bottom) < 0
        const isAboveAnchorSection = pageYOffset < topDeadZone && navActivePos !== 'above'
        const isActiveDefaultAnchorNav =
          pageYOffset > topDeadZone + 61 &&
          pageYOffset < navObject[0].top &&
          activeSection &&
          activeSection.anchorName !== 'none'
        const inActiveNav = pageYOffset > navObject[0].top && pageYOffset < bottomDeadZone
        const isBelowAnchorSection = pageYOffset > bottomDeadZone && navActivePos !== 'below'
        switch (true) {
          case isAboveAnchorSection:
            viewChecker({
              navActivePos: 'above',
              activeSection: {},
            })
            break
          case isBelowAnchorSection:
            viewChecker({
              navActivePos: 'below',
              activeSection: {},
            })
            break
          case isActiveDefaultAnchorNav:
            viewChecker({
              navActivePos: 'validPosition',
              activeSection: {
                anchorName: 'none',
              },
            })
            break
          case inActiveNav:
            for (let index = 0; index < navObject.length; index += 1) {
              const section = navObject[index]
              if (
                isBetween(pageYOffset, section.top, section.bottom) &&
                activeSection.anchorName !== section.anchorName
              ) {
                viewChecker({
                  navActivePos: 'validPosition',
                  activeSection: section,
                })
              }
            }
            break
          default:
            break
        }
      }
    })()
  }

  useEffect(() => {
    const templateLevelData = cmsData && getTemplateLevelAttrs(cmsData)
    const hasAnchorNav = templateLevelData && templateLevelData.hasAnchorNavigation
    const pageYOffset = scrollPosition && scrollPosition.y
    if (hasAnchorNav) {
      setScrollPosition(pageYOffset)
      if (pageYOffset !== pos - 1) checkInView(pos)
    }
  })

  return children
}

AnchorNavProvider.propTypes = {
  activeSection: PropTypes.shape({}),
  bottomDeadZone: PropTypes.number,
  children: PropTypes.node,
  cmsData: PropTypes.shape({
    meta: PropTypes.shape({
      description: PropTypes.string,
      title: PropTypes.string,
    }),
    template: PropTypes.shape({
      data: PropTypes.arrayOf(PropTypes.object),
    }),
  }),
  navActivePos: PropTypes.string,
  navObject: PropTypes.arrayOf(PropTypes.string),
  topDeadZone: PropTypes.number,
  viewChecker: PropTypes.func,
}

export default AnchorNavProvider
