import { useState, useEffect } from 'react'
import {
  Context,
  enableDebugConsoleLogDrain,
  enableContextDevTools,
  CookieTransitionDataStore,
} from '@uniformdev/context'
import { consentCookieHelper } from '@sainsburys-tech/boltui-utils'
import { isArgos } from '../../helpers/brandHelper'
import { updateEnrichment } from '../../helpers/uniform'
import { isCmsPath, isHomepagePath } from '../../helpers/pathCheck'

const hasMarketingConsent = () => {
  const { categories, getConsentCookieValue, getCategorySection, hasOptedIn } = consentCookieHelper
  const consentCategory = 'marketing'
  const cookies = typeof window !== 'undefined' && window.document ? document.cookie : null

  if (!cookies) return false

  const consentCookieValue = getConsentCookieValue(cookies)

  if (!consentCookieValue || !consentCookieValue.includes('consent:') || !categories[consentCategory]) {
    return false
  }

  const categorySection = getCategorySection(consentCookieValue, consentCategory)
  if (!categorySection.length && consentCookieValue.includes('consent:true')) {
    return true
  }

  return hasOptedIn(categorySection[0])
}

const fetchUniformManifest = async () => {
  try {
    const url = 'https://cdn.argos.co.uk/assets/personalisation/manifest.json'
    const data = await fetch(url)
    const json = await data.json()
    return json
  } catch (e) {
    console.error('Error fetching manifest', e)
  }
  return null
}

const setUniformContext = async (setContext) => {
  const plugins = [enableContextDevTools()]
  if (process.env.NODE_ENV !== 'production') {
    plugins.push(enableDebugConsoleLogDrain('debug'))
  }
  const manifest = await fetchUniformManifest()

  if (manifest) {
    const context = new Context({
      defaultConsent: false,
      manifest,
      transitionStore: new CookieTransitionDataStore({}),
      plugins,
    })
    setContext(context)
  }
}

export const UniformTracking = ({ path }) => {
  const [context, setContext] = useState()

  useEffect(() => {
    if (isArgos()) {
      setUniformContext(setContext)
    }
  }, [])

  useEffect(() => {
    const hasConsent = hasMarketingConsent()
    context?.storage.updateData([
      {
        type: 'consent',
        data: hasConsent,
      },
    ])
  }, [context])

  useEffect(() => {
    if (context && path && isCmsPath(path) && !isHomepagePath(path)) {
      updateEnrichment(path, context)
    }
  }, [path, context])

  return null
}
